import React from 'react';
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

export const ConnectorIntro = ({color = 'blue', logo = false, name, description, isPageHeader = false}) => {
  const titleClassName = `leading-11 md:leading-12 font-bold text-3xl md:text-5xl m-auto text-center text-${color} pb-3 mb-8`;
  return (
    <>
      {logo[0]?.node && (
        <GatsbyImage
          fluid={logo[0]?.node.fluid}
          alt="logo"
          image={getImage(logo[0]?.node)}
          className="text-center w-[108px] mb-8"
        />
      )}
      {isPageHeader ? <h1 className={titleClassName}>{name}</h1> : <div className={titleClassName}>{name}</div>}
      <p
        className={`md:text-2xl text-xl max-w-8xl m-auto text-center mb-8 md:mb-16 mx-8 text-${color}`}
        dangerouslySetInnerHTML={{__html: description}}
      ></p>
    </>
  );
};
export const FeaturesButtons = ({dataButtons, handleClick}) => {
  const btnClass =
    'bg-white border w-full lg:w-auto mb-4 mx-auto rounded-xl border-[#0091FF] font-bold text-sm xl:text-lg text-[#0091FF] hover:text-blue px-2 lg:px-3 xl:px-6 py-3';

  return (
    <>
      <div className="flex flex-col justify-between mx-8 mb-16 md:flex-row lg:mb-24">
        {dataButtons?.map((dataButton, index) => (
          <button className={btnClass} onClick={() => handleClick({index})} key={`btnfeature-${index}`}>
            {dataButton.label}
          </button>
        ))}
      </div>
    </>
  );
};
export const ConnectorOverview = ({title, overview = 'Overview', description, image = []}) => {
  const titleClassName = `leading-11 md:leading-12 font-bold text-3xl md:text-5xl m-auto text-center text-blue mb-16`;
  return (
    <div className="mx-8">
      <div className={titleClassName}>{title}</div>
      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">{overview}</h2>
      <p
        className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}
        dangerouslySetInnerHTML={{__html: description}}
      ></p>
      <div className="pb-8 mb-8">
        {image[0]?.node && (
          <GatsbyImage
            fluid={image[0]?.node.fluid}
            alt="dashboard"
            image={getImage(image[0]?.node)}
            className="drop-shadow-xl md:drop-shadow-2xl"
          />
        )}
      </div>
    </div>
  );
};

export const SeeAllArrow = () => {
  return (
    <Link to="/product/integrations">
      <StaticImage src="../../images/arrow-left.svg" className="w-4 md:w-8" alt="coming soon" />
      <span className="pl-4 md:pl-8">See All Integrations</span>
    </Link>
  );
};
