import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, SeeAllArrow} from '../../../../../components/systems';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Github = () => {
  const connectorName = 'github';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/github/"}, extension: {regex: "/(jpg)|(png)/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
              fluid(maxWidth: 800, quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    overview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    featureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`)[0]?.node,
    featureTwo: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-01`)[0]?.node,
    logo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
    icons: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-03`)[0]?.node,
  };

  const connector = {
    intro: {
      logo: images.logo,
      name: 'GitHub',
      description: `<u>GitHub</u> is a leading cloud platform for hosting code, and it includes features for version control and collaboration. GitHub lets your development teams work together on projects from anywhere in the world. GitHub also offers DevOps features for Continuous Integration and Continuous Deployment (CI/CD).<br><br> 
Trustle integrates with the cloud-based versions of GitHub to help teams manage their membership and access to repositories. `,
    },
    overview: {
      title: 'Trustle w/ GitHub',
      overview: 'Overview',
      description: `Trustle integrates with GitHub to identify risky accounts and manage access to code repositories that range from open source to highly sensitive. Trustle’s GitHub connector takes into consideration highly security-sensitive roles—Maintainer and Member roles for Teams—and enables you to set the  sensitivity of repositories. With these designation, Trustle makes establishes the appropriate workflows for access requests and approvals. <br><br>
The GitHub connector also helps you identify unused and over-privileged accounts and use a teams-based model for access to appropriate code repositories. `,
      imageLeft: images.featureOne,
      imageRight: images.featureTwo,
      imageIcons: images.icons,
    },
  };
  const {intro, overview} = connector;

  return (
    <Layout
      seo={
        <Seo
          title="Github System | Trustle"
          description="Trustle integrates with the cloud-based versions of GitHub to help teams manage their membership and access to repositories."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />

              <div className="m-auto mb-16 text-3xl font-bold text-center leading-11 md:leading-12 md:text-5xl text-blue">
                Trustle w/ GitHub
              </div>
              <div className="mx-8">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Overview</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Trustle integrates with GitHub to identify risky accounts and manage access to code repositories that
                  range from open source to highly sensitive. Trustle’s GitHub connector takes into consideration highly
                  security-sensitive roles—Maintainer and Member roles for Teams—and enables you to set the sensitivity
                  of repositories. With these designation, Trustle makes establishes the appropriate workflows for
                  access requests and approvals. The GitHub connector also helps you identify unused and over-privileged
                  accounts and use a teams-based model for access to appropriate code repositories.
                </p>
              </div>

              {/* to do: create this as component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="">
                    <div className="max-w-[500px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">
                        Manage by System Permissions, Repositories, and Teams
                      </h2>
                      <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                        Trustle monitors and displays your all effective Permissions, Repositories, and Teams. System
                        owners can make GitHub Teams visible in the Trustle catalog, so users can request membership as
                        needed. Trustle takes care of the approval workflows based on the sensitivity of the resource
                        requested—in particular Temporary, Just-in-Time, or No Access.
                      </p>
                    </div>
                  </div>

                  <div className="pb-8">
                    {overview?.imageRight && (
                      <GatsbyImage
                        fluid={overview.imageRight.fluid}
                        alt="dashboard image"
                        image={getImage(overview.imageRight)}
                        className="max-w-[730px] drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* to do: create this as component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="pb-8">
                    {overview?.imageLeft && (
                      <GatsbyImage
                        fluid={overview.imageLeft.fluid}
                        alt="dashboard image"
                        image={getImage(overview.imageLeft)}
                        className="max-w-[673px] drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                  <div className="">
                    <div className="max-w-[530px]">
                      <div className="mb-4 text-lg font-bold text-left sm:text-3xl">
                        Visibility into Repository Access
                      </div>
                      <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                        Trustle provides views on all the repositories in your GitHub and the permissions that users
                        hold.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-3xl">Trustle Account Types</div>
                      <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                        Additionally, Trustle identifies several types of accounts—namely Employee, Customer, System,
                        and Contractor—which makes it easier to maintain different policies for each class of user.
                      </p>
                      {overview?.imageIcons && (
                        <GatsbyImage
                          fluid={overview.imageIcons.fluid}
                          alt="dashboard image"
                          image={getImage(overview.imageIcons)}
                          className="max-w-[500px]"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Github;
